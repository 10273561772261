import React, { Component } from "react"
import Container from "react-bootstrap/container"
import Row from "react-bootstrap/row"
import Slider from "react-slick"

import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css"

import BusinessStyles from "./business.module.scss"

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
          },
        },
      ],
    }
    return (
      <div id="business">
        <Container>
          <Row className="sectionTitle">
            <h2>Business Delegation Tours</h2>
          </Row>
          <Row className="sectionContent">
            <p>
              To help our clients to navigate and explore on the plentiful
              business opportunities overseas and facilitate their global market
              development, we organize business delegation tours to different
              designated countries around the world for elite groups of clients,
              with site visits and various business meetings.
            </p>
          </Row>

          <Slider {...settings} style={{ padding: "200px" }}>
            <div>
              <img
                src="/static/img/B Tour-1.jpg"
                alt="Business Delegation Tour 1"
                className={BusinessStyles.images}
              />
            </div>
            <div>
              <img
                src="/static/img/B Tour-1A.jpg"
                alt="Business Delegation Tour 2"
                className={BusinessStyles.images}
                style={{width: "40%", maxWidth: "325px"}}
              />
            </div>
            <div>
              <img
                src="/static/img/B Tour-2.jpg"
                alt="Business Delegation Tour 3"
                className={BusinessStyles.images}
              />
            </div>
            <div>
              <img
                src="/static/img/B Tour-3.jpg"
                alt="Business Delegation Tour 4"
                className={BusinessStyles.images}
              />
            </div>
          </Slider>
        </Container>
      </div>
    )
  }
}
