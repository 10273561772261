import React from "react"

import Container from "react-bootstrap/container"
import Row from "react-bootstrap/row"
import Col from "react-bootstrap/col"

import BlueTop from "./blue-top"

import HeroStyles from "./hero.module.scss"

const Hero = () => {
  return (
    <div className={HeroStyles.background}>
      <Container className={HeroStyles.container}>
        <Row className={HeroStyles.content}>
          <Col lg={7} md={12} className={HeroStyles.wordContent}>
            <Row className={HeroStyles.heroRowTop}>
              <h1>Brillicapital Intelligence Consultancy</h1>
            </Row>
            <Row className={HeroStyles.heroRowBottom}>
              <h3>Expert in International Business Consultancy</h3>
            </Row>
          </Col>
          <Col lg={4} md={12} className={HeroStyles.imageContent}>
            <img
              src="../../static/img/logo.png"
              alt="Brillicapital Intelligence Consultancy Logo"
            />
          </Col>
        </Row>
      </Container>
      <BlueTop />
    </div>
  )
}

export default Hero
