import React from "react"

import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"

// import smooth scrolling
import Scrollchor from "react-scrollchor"

import HeaderStyles from "./header.module.scss"

export default class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      offset1: 0,
      offset2: 0,
      offset3: 0,
      expanded: false,
    }
  }

  setExpanded = e => {
    this.setState({ expanded: e })
  }

  updateDimensions() {
    if (window.innerWidth >= 992) {
      this.setState({ offset1: -100, offset2: 0, offset3: -65 })
    } else if (window.innerWidth <= 767) {
      this.setState({ offset1: -180, offset2: -80, offset3: -80 })
    } else {
      this.setState({ offset1: -180, offset2: -80, offset3: -80 })
    }
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("load", this.updateDimensions.bind(this))
    window.addEventListener("resize", this.updateDimensions.bind(this))
  } 

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this))
  }

  render() {
    const offset1 = this.state.offset1
    const offset2 = this.state.offset2
    const offset3 = this.state.offset3
    return (
      <Navbar
        bg="light"
        expand="lg"
        sticky="top"
        expanded={this.state.expanded}
        onToggle={v => this.setExpanded(v)}
        className={HeaderStyles.navbarPadding}
      >
        <Navbar.Brand>
          {" "}
          <Scrollchor to="#">
            <img
              src="../../static/img/logo2.png"
              alt="Brillicapital Logo"
              style={{ width: "7vw", minWidth: "60px" }}
            />
          </Scrollchor>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="ml-auto">
            <Scrollchor
              animate={{ offset: offset1 }}
              to="#about"
              beforeAnimate={() => this.setExpanded(false)}
              className={HeaderStyles.links}
            >
              About Us
            </Scrollchor>
            <Scrollchor
              animate={{ offset: offset2 }}
              to="#foreign"
              beforeAnimate={() => this.setExpanded(false)}
              className={HeaderStyles.links}
            >
              Foreign Direct <br className={HeaderStyles.breaks} />
              Investment
            </Scrollchor>
            <Scrollchor
              animate={{ offset: offset1 }}
              to="#business"
              beforeAnimate={() => this.setExpanded(false)}
              className={HeaderStyles.links}
            >
              Business <br className={HeaderStyles.breaks} />
              Delegation Tours
            </Scrollchor>
            <Scrollchor
              animate={{ offset: offset2 }}
              to="#citizenship"
              beforeAnimate={() => this.setExpanded(false)}
              className={HeaderStyles.links}
            >
              Citizenship By <br className={HeaderStyles.breaks} />
              Investment Programs
            </Scrollchor>
            <Scrollchor
              animate={{ offset: offset3 }}
              to="#contact"
              beforeAnimate={() => this.setExpanded(false)}
              className={HeaderStyles.links}
            >
              Contact Us
            </Scrollchor>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}
