import React from "react"

const BlueBottom = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "100%", height: "100px", color: "#98B5FF" }}
      preserveAspectRatio="none"
      viewBox="0 0 200 50"
    >
      <path fill="currentColor" d="M0,0 L200,100 L0,100 L200,0z" />
      {/* <path fill="currentColor" d="M0,0 L0,50 L200,50 L200,0 L100,50z" /> */}
    </svg>
  )
}

export default BlueBottom
