import React from "react"
import Container from "react-bootstrap/container"
import Row from "react-bootstrap/row"
import Col from "react-bootstrap/col"

import PolicyModal from "./policyModal"

import FooterStyles from "./footer.module.scss"

const Footer = () => {
  const termsAndConditions = {
    name: "Terms and Conditions",
    content: [
      `Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Brillicapital Intelligence Consultancy's relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website. `,
      <br />,
      <br />,
      `The term '[business name]' or 'us' or 'we' refers to the owner of the website. The term 'you' refers to the user or viewer of our website. The use of this website is subject to the following terms of use:`,
      <br />,
      <br />,
      <ul>
        <li>
          {" "}
          The content of the pages of this website is for your general
          information and use only. It is subject to change without notice.{" "}
        </li>
        <li>
          {" "}
          This website may use cookies to monitor browsing preferences. Please
          read our cookie policy for further information.{" "}
        </li>
        <li>
          {" "}
          Neither we nor any third parties provide any warranty or guarantee as
          to the accuracy, timeliness, performance, completeness or suitability
          of the information and materials found or offered on this website for
          any particular purpose. You acknowledge that such information and
          materials may contain inaccuracies or errors and we expressly exclude
          liability for any such inaccuracies or errors to the fullest extent
          permitted by law.{" "}
        </li>
        <li>
          {" "}
          Your use of any information or materials on this website is entirely
          at your own risk, for which we shall not be liable. It shall be your
          own responsibility to ensure that any products, services or
          information available through this website meet your specific
          requirements.{" "}
        </li>
        <li>
          {" "}
          This website contains material which is owned by or licensed to us.
          This material includes, but is not limited to, the design, layout,
          look, appearance and graphics. Reproduction is prohibited other than
          in accordance with the copyright notice, which forms part of these
          terms and conditions.{" "}
        </li>
        <li>
          {" "}
          All trade marks reproduced in this website which are not the property
          of, or licensed to, the operator are acknowledged on the website.{" "}
        </li>
        <li>
          {" "}
          Unauthorized use of this website may give rise to a claim for damages
          and/or be a criminal offence.{" "}
        </li>
        <li>
          {" "}
          From time to time this website may also include links to other
          websites. These links are provided for your convenience to provide
          further information. They do not signify that we endorse the
          website(s). We have no responsibility for the content of the linked
          website(s).
        </li>
      </ul>,
    ],
  }

  const privacyPolicy = {
    name: "Privacy Policy",
    content: [
      `This privacy policy ('policy') will help you understand how Brillicapital Intelligence Consultancy ("us", "we", "our") uses and protects the data you provide to us when you visit and use www.brilcapital.com.hk ("website", "service").`,
      <br />,
      <br />,
      `We reserve the right to change this policy at any given time. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.`,
      <br />,
      <br />,
      <br />,
      <b>What User Data We Collect</b>,
      <br />,
      <br />,
      `When you visit the website, we may collect the following data:`,
      <ul>
        <li>Your IP address.</li>
        <li>Your contact information and email address.</li>
        <li>Other information such as interests and preferences.</li>
        <li>Data profile regarding your online behavior on our website.</li>
      </ul>,
      <br />,
      <br />,
      <b>Why We Collect Your Data</b>,
      <br />,
      <br />,
      `We are collecting your data for several reasons:`,
      <ul>
        <li>To better understand your needs.</li>
        <li>To improve our services and products.</li>
        <li>
          To send you promotional emails containing the information we think you
          will find interesting.
        </li>
        <li>
          To contact you to fill out surveys and participate in other types of
          market research.
        </li>
        <li>
          To customize our website according to your online behavior and
          personal preferences.
        </li>
      </ul>,
      <br />,
      <b>Safeguarding and Securing the Data</b>,
      <br />,
      <br />,
      `We are committed to securing your data and keeping it confidential. We have done all in its power to prevent data theft, unauthorized access, and disclosure by implementing the latest technologies and software, which help us safeguard all the information we collect online.`,
      <br />,
      <br />,
      <br />,
      <b>Restricting the Collection of your Personal Data</b>,
      <br />,
      <br />,
      `At some point, you might wish to restrict the use and collection of your personal data. You can achieve this by contacting us via email and we will be more than happy to change this for
      you.`,
    ],
  }

  const cookiePolicy = {
    name: "Cookie Policy",
    content: [
      `Once you agree to allow our website to use cookies, you also agree to use the data it collects regarding your online behavior (analyze web traffic, web pages you spend the most time on, and websites you visit).`,
      <br />,
      <br />,
      `The data we collect by using cookies is used to customize our website to your needs. After we use the data for statistical analysis, the data is completely removed from our systems.`,
      <br />,
      <br />,
      `Please note that cookies don't allow us to gain control of your computer in any way. They are strictly used to monitor which pages you find useful and which you do not so that we can provide a better experience for you.`,
      <br />,
      <br />,
      `If you want to disable cookies, you can do it by accessing the settings of your internet browser:`,
      <br />,
      <br />,
      <ul>
        <li>
          <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en">
            Google Chrome
          </a>
        </li>
        <li>
          <a href="https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox">
            Mozilla Firefox
          </a>
        </li>
        <li>
          <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">
            Internet Explorer
          </a>
        </li>
        <li>
          <a href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy">
            Microsoft Edge
          </a>
        </li>
        <li>
          <a href="https://help.opera.com/en/latest/security-and-privacy/">
            Opera
          </a>
        </li>
      </ul>,
    ],
  }
  return (
    <div id="footer">
      <Container>
        <Row className="sectionTitle">
          <Col
            style={{ textAlign: "left" }}
            className={FooterStyles.textContent}
          >
            <ul>
              <li>
                {" "}
                <PolicyModal
                  name={termsAndConditions.name}
                  content={termsAndConditions.content}
                />
              </li>
              <li>
                {" "}
                <PolicyModal
                  name={privacyPolicy.name}
                  content={privacyPolicy.content}
                />
              </li>
              <li>
                {" "}
                <PolicyModal
                  name={cookiePolicy.name}
                  content={cookiePolicy.content}
                />
              </li>
            </ul>
          </Col>
          <Col
            style={{ textAlign: "right" }}
            className={FooterStyles.textContent}
          >
            <ul>
              <li>© 2019 Brillicapital Intelligence Consultancy</li>
              <li>All Rights Reserved</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Footer
