import React from "react"

import Container from "../../node_modules/react-bootstrap/container"
import Row from "../../node_modules/react-bootstrap/row"

const About = () => {
  return (
    <div id="about">
      <Container>
        <Row className="sectionTitle">
          <h2>About Us</h2>
        </Row>
        <Row className="sectionContent">
          <p>
            Brillicapital Intelligence Consultancy is an expert in international
            business consultancy, with headquarters in Hong Kong. We focus on
            offering expertise advice in various investment areas, such as
            foreign direct investment, overseas market development, citizenship
            by investment programs, for prestigious and elite groups of high net
            worth clients. We strive in helping our clients to excel in overseas
            markets expansion and fostering the growth of their businesses
            through various investment opportunities in different countries.
            <br />
            <br />
            Our team of dedicated executives with professionalism and
            intelligence are highly committed to helping our clients to achieve
            brilliant success in business development and investment projects,
            through our premier network of entrusted professionals who would
            help streamline the process of foreign investment with strong
            support from local governments. <br />
            <br /> We partner with financial institutions and chambers of
            commerce to offer professional advice to a profusion of superb and
            high net worth clients, with diversified investment interests, such
            as property development and investment, tourism, food industry, etc.
            We also organize business delegation tours to different foreign
            countries, for investors to explore bountiful business opportunities
            there. Other related services in foreign investment will be offered
            as well.
          </p>
        </Row>
      </Container>
    </div>
  )
}

export default About
