import React from "react"

const BlueTop = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "100%", height: "100px", color: "#98B5FF"}}
      preserveAspectRatio="none"
      viewBox="0 0 200 50"
    >
       <path fill="currentColor" d="M0,0 L0,50.5 L200.25,50.5 L200.25,0 L100,50.5z" />
    </svg>
  )
}

export default BlueTop
