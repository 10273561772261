import React from "react"

import Container from "react-bootstrap/container"
import Row from "react-bootstrap/row"

import ContactStyles from "./contact.module.scss"

const Contact = () => {
  return (
    <div id="contact">
      <Container>
        <Row className={ContactStyles.spacer} />
        <Row className="sectionTitle">
          <h2>Contact Us</h2>
        </Row>
        <Row
          className="sectionContent"
          style={{
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className={ContactStyles.wordContent}>
            <p>
              Address:&emsp;Unit 1010-1015, 10/F, Tower B, New Mandarin Plaza,
              <br style={{ margin: "0", padding: "0" }} />
              &emsp;&emsp;&emsp;&emsp;&emsp;Tsim Sha Tsui East, Kowloon, Hong
              Kong{" "}
            </p>
            <p>Tel: &emsp;&emsp;&emsp;(852) 3584 8151 </p>
            <p>Fax:&emsp;&emsp;&emsp;(852) 3007 5781 </p>
            <p>Email:&emsp;&emsp;info@brilcapital.com.hk</p>
          </div>
          {/* <Col lg={5} md={4} sm={2} >
            <p>
              Address: <br />
              <br />
              Tel: <br />
              Fax: <br />
              Email:
            </p>
          </Col>
          <Col className={ContactStyles.content}>
            <p>
              Unit 1010-1015, 10/F, Tower B, New Mandarin Plaza, <br />
              Tsim Sha Tsui East, Kowloon, Hong Kong <br />
              (852) 3584 8151 <br />
              (852) 3007 5781 <br />
              info@brilcapital.com.hk
            </p>
          </Col> */}
        </Row>
        <Row className={ContactStyles.spacer} />
      </Container>
    </div>
  )
}

export default Contact
