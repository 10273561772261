import React from "react"
import Container from "../../node_modules/react-bootstrap/container"
import Row from "../../node_modules/react-bootstrap/row"
import Col from "../../node_modules/react-bootstrap/col"

import ForeignStyles from "./foreign.module.scss"

import BlueBottom from "./blue-bottom"
import BlueTop from "./blue-top"

const Foreign = () => {
  return (
    <div id="foreign">
      <BlueBottom />
      <Container>
        <Row className="sectionTitle">
          <h2>Foreign Direct Investment</h2>
        </Row>
        <Row className="sectionContent">
          <p>
            We offer expertise advice in foreign direct investment & overseas
            market development for our high net worth clients, with broad areas
            of investment interests. To highlight, property development,
            property investment, hotels & tourism, infrastructure, agriculture,
            food industry, health care, trading, technology & e-businesses are
            some popular and key investment areas with profitable development
            projects. Our professional team is dedicated in identifying unique,
            distinctive and exceptional investment opportunities overseas for
            our prestigious clients, and streamlining the process with strong
            and solid local support.
          </p>
          <Row>
            <Col lg={4} className={ForeignStyles.columns}>
              <img
                src="/static/img/FID-1.jpg"
                alt="Foreign Direct Investment 1"
                className={ForeignStyles.images}
              />
            </Col>
            <Col lg={4} className={ForeignStyles.columns}>
              <img
                src="/static/img/FID-2.jpg"
                alt="Foreign Direct Investment 2"
                className={ForeignStyles.images}
              />
            </Col>
            <Col lg={4} className={ForeignStyles.columns}>
              <img
                src="/static/img/FID-3.jpg"
                alt="Foreign Direct Investment 3"
                className={ForeignStyles.images}
              />
            </Col>
          </Row>
        </Row>
      </Container>
      <BlueTop />
    </div>
  )
}

export default Foreign
