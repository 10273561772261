import React from "react"

import Container from "react-bootstrap/container"
import Row from "react-bootstrap/row"
import Col from "react-bootstrap/col"

import BlueTop from "./blue-top"
import BlueBottom from "./blue-bottom"
import CitizenshipStyles from "./citizenship.module.scss"

const Citizenship = () => {
  return (
    <div id="citizenship">
      <BlueBottom />
      <Container>
        <Row className="sectionTitle">
          <h2>Citizenship By Investment Programs</h2>
        </Row>
        <Row className="sectionContent">
          <Row>
            <p>
              Citizenship By Investment Programs offer a direct, convenient,
              speedy and classy way which is easily manageable for individuals
              to obtain a new or second citizenship and passport legally. This
              has become a popular and prevalent trend, particularly for those
              supreme groups of clients who are looking for security and those
              who want to get easy access for travelling to different countries
              globally for business and other purposes. There is a recent saying
              that second citizenship has become “another trophy for the
              ultra-wealthy”! <br />
              <br />
              One of the most direct ways for such programs is through
              investment in real estate which also yields high returns. We
              collaborate with our strong networks of dedicated professionals to
              offer expertise advice to our clients and help them smoothen the
              whole process proficiently.
            </p>
          </Row>
          <Row>
            <Col lg={6} className={CitizenshipStyles.columns}>
              <img
                className={CitizenshipStyles.images}
                src="static/img/CBI-1.jpg"
                alt="Citizen By Investment 1"
              />
            </Col>
            <Col lg={6} className={CitizenshipStyles.columns}>
              <img
                className={CitizenshipStyles.images}
                src="static/img/CBI-2.jpg"
                alt="Citizen By Investment 2"
              />
            </Col>
          </Row>
        </Row>
      </Container>
      <BlueTop />
    </div>
  )
}

export default Citizenship
