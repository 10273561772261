import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import About from "../components/about"
import Foreign from "../components/foreign"
import Business from "../components/business"
import Citizenship from "../components/citizenship"
import Contact from "../components/contact"

import "../styles/index.scss"

const IndexPage = () => {
  return (
    <div>
      <Layout>
        <Hero />
        <About />
        <Foreign />
        <Business />
        <Citizenship />
        <Contact />
      </Layout>
    </div>
  )
}

export default IndexPage
